<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">My Messages</h2>
      <div class="alert alert-info">Select a chat to display</div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>